<template>
  page2...
</template>

<script>

// import LayoutHeader from "@/views/layout/layout-site-header";
// import LayoutFooter from "@/views/layout/layout-site-footer";

export default {
  components: {
    // LayoutHeader,
    // LayoutFooter,
  },

  setup() {},
};
</script>

<style>
</style>
